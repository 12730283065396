

// import axios from 'axios';
// import React, { useState } from 'react';
// import { useNavigate, useSearchParams } from 'react-router-dom';
// import Cookies from 'js-cookie'

// const SdpRedirect = () => {
//     const [loading, setLoading] = useState(true);
//     const [q] = useSearchParams();
//     const navigate = useNavigate();
//     const subdomain='cashhub'
//     // const subdomain = window.location.hostname.split('.')[0];
//     console.log(subdomain,"-------")

//     const svc_id=349
//     let msisdn = q.get("msisdn");
//     // let svc_id=q.get("svc_id")
//   let ext_ref = q.get("ext_ref");

//     let subscription_id = q.get("subscription_id");

//     console.log("redirect data", msisdn, subscription_id);

//     axios.get(`https://sdpcallback.ndotomedia.com/check-sub?msisdn=${msisdn}&subscription_id=${subscription_id}`)
//         .then(resp => {
//             const data = resp.data;
//             console.log("check-data =>", data);
//             const datta=data
//             console.log("datataaa",data)
//             setLoading(false);

//             if (data.statusId =='1') { 
//              Cookies.set('msisdn',msisdn)
//              navigate('/')
//             // navigate(`/redirect/${subdomain}?msisdn=${msisdn}&result=Active`);

             
//             } 

//             if (data.statusId == '0'){
//                 navigate('/login')
//                 // window.location.replace(`https://sdp-s-vas-payment.telkom.co.za/${svc_id}?msisdn=${msisdn}&subscription_id=${subscription_id}&ext_ref=${ext_ref}`)
//             }
//         })
//         .catch(e => {
//             setLoading(false);
//             navigate("/");
//         });

//     if (loading) return <h1>Redirecting....</h1>;
//     return null;
// };

// export default SdpRedirect;


import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';

const SdpRedirect = () => {
    const [loading, setLoading] = useState(true);
    const [q] = useSearchParams();
    const navigate = useNavigate();
    // const subdomain = window.location.hostname.split('.')[0];
    const subdomain='CashHub'

    useEffect(() => {
        let msisdn = q.get("msisdn");
        let ext_ref = q.get("ext_ref");
        let subscription_id = q.get("subscription_id");

        console.log("redirect data", msisdn, subscription_id);
        console.log("subdomain", subdomain);

        axios.get(`https://sdpcallback.ndotomedia.com/check-sub?msisdn=${msisdn}&subscription_id=${subscription_id}&svc_name=${subdomain}`)

        // axios.get(`https://sdpcallback.ndotomedia.com/check-sub?msisdn=${msisdn}&subscription_id=${subscription_id}&svc_name=${subdomain}`)
            .then(resp => {
                const data = resp.data;
                console.log("check-data =>", data);
                setLoading(false);

                if (data.statusId == '1') {
                    Cookies.set('msisdn', msisdn);
                    navigate('/');
                }

                if (data.statusId == '0') {
                    navigate('/login');
                }
            })
            .catch(e => {
                setLoading(false);
                navigate("/");
            });
    }, [q, navigate, subdomain]);

    if (loading) return <h1>Redirecting....</h1>;
    return null;
};

export default SdpRedirect;
