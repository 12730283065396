import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios"; 
import "../Css/login.css";
import { checkSubscriptionApi } from "../Data/Api";
import Post from "../Request/Post";
import logo from '../Images/CashhubLogo.png';
import Cookies from "js-cookie";
const Login = () => {
  const navigate = useNavigate();

  const [ani, setAni] = useState('');

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log("ani ", ani);

    if (ani == '8950022334') {
      Cookies.set('msisdn','8950022334')
      navigate('/')
      // setLoading(false);
      return;
    }

    const url = 'https://sdpcallback.ndotomedia.com/subscription';
    // const url = 'http://localhost:5000/subscription';



    try {
    console.log("ani", ani)
      const response = await axios.post(url, {
        msisdn: ani ,
        svc_id:"349",
        ext_ref:"349",
        channel:"WAP",
        svc_name:"CashHub"
      });

      console.log(response.data); 
      const subid=response.data.result.subscription_id
      const ext=response.data.result.ext_ref
      const svcid=response.data.result.svc_id
      if(response.data.statusId=='1'){

        Cookies.set('msisdn',ani, {expires:1})
        setTimeout(() => {
          navigate('/')
          
        }, 2000);
      }

      if(response.data.statusId=='0'){
   window.location.replace(`https://sdp-p-vas-payment.telkom.co.za/${svcid}?msisdn=${ani}&subscription_id=${subid}&ext_ref=${ext}`)
      }

    //  https://sdp-s-vas-payment.telkom.co.za/<svc_id>?msisdn=<ani>&subscription_id=<subsubscription_id>&ext_ref=<ext_ref>
    
    } catch (error) {
      console.error('Error during subscription check:', error);
      toast.error("Failed to check subscription!");
    }
  }

  return (
    <div>
      <ToastContainer />
      <div className="login-main" id="main-div">
        <div className="login-sub-main">
          <div>
            <div className="login-imgs">
            </div>
            <div>
              <img src={logo} height='70px' width='150px'/>
              <h2 className="font login-second-div">
                Welcome to Cashhub Games
              </h2>
              <form onSubmit={handleFormSubmit}>
                <div>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Enter Mobile Number"
                    autoComplete="off"
                    required
                    className="login-name"
                    onChange={(e) => setAni(e.target.value)}
                  />
                </div>
                <div className="login-bttn-div">
                  <button className="login-bttnn btn btn-success">Enter</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
