// const pre=`http://localhost:2932/`;
// const pre=`http://167.235.3.186:2932/`;
// const pre=`https://88.99.5.236:2932/`;
// https://www.cashubbackend.ndotomobile.com
const pre=`https://cashubbackend.ndotomobile.com/`;
const getGamesApi=`${pre}sendGames`;
export{getGamesApi};

const checkSubscriptionApi=`${pre}checkSubscription`;
export{checkSubscriptionApi};

const sendScoreApi=`${pre}sendScore`;
export{sendScoreApi};

const sendLeaderboardApi=`${pre}sendLeaderboard`;
export{sendLeaderboardApi};

const sendPrizesApi=`${pre}sendPrizes`;
export{sendPrizesApi};

